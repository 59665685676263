:root {
  --primary-color: #3B6282;
  --header-background: #3B6282;
  --header-text-color: #fff;
  --table-border: #ddd;
  --assign-color: #e74c3c;
  --unassign-color: #2ecc71;
  --hover-color: rgba(56, 182, 255, 0.1); /* Light blue hover effect */
}

.user-numbers {
  padding: 25px;
  max-width: 900px;
  margin: 200px auto;
  background-color: rgba(255, 255, 255, 0.9); /* Transparent white background */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* More prominent box-shadow */
}

/* Heading */
h2 {
  text-align: center;
  color: white; /* Make heading text white */
  background-color: #38b6ff; /* Match the secondary color background */
  padding: 15px;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for readability */
}

/* Numbers table */
.numbers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.numbers-table th, 
.numbers-table td {
  padding: 15px;
  text-align: center; /* Center-align table text */
  font-size: 16px;
  border: none;
}

.numbers-table th {
  background-color: #ff3131; /* Red background for the table headers */
  color: white; /* White text */
  font-weight: bold;
}

.numbers-table td {
  color: #333; /* Darker text for better contrast */
}

.numbers-table tr:nth-child(even) {
  background-color: var(--hover-color); /* Light blue hover effect */
}

.numbers-table tr:hover {
  background-color: rgba(56, 182, 255, 0.2); /* Hover effect similar to other designs */
  transition: background-color 0.3s ease;
}

p {
  text-align: center;
  color: #777;
  font-size: 16px;
  margin-top: 20px;
}

/* Button Styles */
button {
  padding: 10px 18px;
  background-color: #38b6ff;  /* Match the secondary color */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-size: 14px;
}

button:hover {
  background-color: #ff3131; /* Red hover color */
}

button.assign {
  background: linear-gradient(135deg, #ff3131, #ff6b6b);
}

button.unassign {
  background: linear-gradient(135deg, #2ecc71, #27ae60);
}

button.unassign:hover {
  background: linear-gradient(135deg, #27ae60, #1abc9c);
}

button.assign:hover {
  background: linear-gradient(135deg, #e52b2b, #ff6b6b);
}
