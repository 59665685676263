/* Admin panel styling */
.admin-panel {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Transparent background */
  width: calc(100% - 250px);
  box-sizing: border-box;
  margin: 0 auto; /* Center the panel */
}

/* Stats section */
.stats-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.stat-box {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-right: 10px;
}

.stat-box h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
  background-color: #38b6ff; /* Secondary color */
  padding: 10px;
  border-radius: 8px;
}

.stat-box p {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

/* Dropdowns and buttons */
.dropdown-filters {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.dropdown {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.buttons-container {
  margin-top: 20px;
}

/* Updated Button Styles */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(135deg, #38b6ff, #33a3e4); /* Gradient for buttons */
  color: white;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

button:hover {
  background: linear-gradient(135deg, #33a3e4, #38b6ff); /* Inverted gradient on hover */
  transform: scale(1.05);
}

/* Red button style for clearing logs */
.clear-button {
  background: linear-gradient(135deg, #ff3131, #ff6b6b); /* Red gradient */
}

.clear-button:hover {
  background: linear-gradient(135deg, #e52b2b, #ff6b6b); /* Hover state for red button */
}

/* Message log table */
.message-log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.message-log-table th,
.message-log-table td {
  padding: 12px;
  border: 1px solid #ddd;
  word-wrap: break-word;
  white-space: normal;
}

.message-log-table th {
  background-color: #38b6ff;
  color: white;
}

.message-log-table td {
  color: #333;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
}

.modal-content h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.modal-content textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  resize: none;
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

/* Modal button styles */
.modal-actions button {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: linear-gradient(135deg, #38b6ff, #33a3e4);
  color: white;
}

.modal-actions button:hover {
  background: linear-gradient(135deg, #33a3e4, #38b6ff);
  transform: scale(1.05);
}

/* Modal cancel button style */
.modal-actions button:last-child {
  background: linear-gradient(135deg, #ff3131, #ff6b6b); /* Red gradient for cancel button */
}

.modal-actions button:last-child:hover {
  background: linear-gradient(135deg, #e52b2b, #ff6b6b);
}

/* Responsive Design */

@media (max-width: 1024px) {
  .stats-container {
    flex-direction: column;
  }

  .stat-box {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .dropdown-filters {
    flex-direction: column;
  }

  .modal {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .admin-panel {
    width: 100%;
    padding: 15px;
  }

  .stat-box h4 {
    font-size: 16px;
  }

  .stat-box p {
    font-size: 20px;
  }

  .dropdown-filters {
    flex-direction: column;
  }

  .message-log-table th, 
  .message-log-table td {
    font-size: 14px;
  }

  button {
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .stats-container {
    flex-direction: column;
  }

  .stat-box {
    margin-bottom: 10px;
  }

  .admin-panel {
    padding: 10px;
  }

  button {
    padding: 7px 12px;
    font-size: 12px;
  }

  .message-log-table th, 
  .message-log-table td {
    font-size: 12px;
    padding: 8px;
  }

  .modal {
    width: 90%;
    padding: 15px;
  }
}
