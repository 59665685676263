:root {
  --primary-color: #3B6282;
  --header-background: #3B6282;
  --header-text-color: #fff;
  --table-border: #ddd;
  --assign-color: #e74c3c;
  --unassign-color: #2ecc71;
  --hover-color: rgba(56, 182, 255, 0.1); /* Light blue hover effect */
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: #F6F9FF; /* Use the new background color */
  overflow-x: hidden;
  box-sizing: border-box;
}

/* Main content area, centered horizontally and vertically */
.main-content {
  width: calc(100% - 250px); /* Adjust for sidebar width */
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-sizing: border-box;
  margin-left: 250px;
  background-color: none;
}

/* Twilio numbers container */
.twilio-numbers {
  width: 150%;
  max-width: 1000px; /* Adjust as needed for table size */
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

/* Twilio table */
.twilio-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.twilio-table th, .twilio-table td {
  border: none;
  padding: 15px;
  text-align: center;
  word-wrap: break-word;
  font-size: 16px;
}

.twilio-table th {
  background-color: #ff3131;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

/* Twilio Numbers heading inside the table as a row */
.twilio-table .twilio-heading-row th {
  background-color: #38b6ff; /* Secondary color background */
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

.twilio-table tr:hover {
  background-color: rgba(56, 182, 255, 0.2);
  transition: background-color 0.3s ease;
}

/* Assign and Unassign button styles */
.assign-button, .unassign-button {
  background: linear-gradient(135deg, var(--assign-color), #ff6b6b);
  color: white;
  border: none;
  padding: 10px 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  font-size: 14px;
}

.assign-button:hover {
  background: linear-gradient(135deg, #e52b2b, #ff6b6b);
  transform: scale(1.05);
}

.unassign-button {
  background: linear-gradient(135deg, var(--unassign-color), #27ae60);
}

.unassign-button:hover {
  background: linear-gradient(135deg, #27ae60, #1abc9c);
  transform: scale(1.05);
}

/* Popup with secondary color background and no stripes */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #38b6ff; /* Secondary color background */
  padding: 30px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  width: 400px;
  z-index: 999;
  color: white;
}

/* Popup content for user selection */
.popup-content {
  text-align: center;
}

/* Updated popup heading (white and bold) */
.popup-content h3 {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Dropdown inside popup */
.user-dropdown {
  padding: 10px;
  width: 80%;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 16px;
}

/* Popup buttons */
.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.assign-button, .cancel-button {
  background-color: #ff3131;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
}

.assign-button:hover, .cancel-button:hover {
  background-color: #e52b2b;
}

.cancel-button {
  background-color: #38b6ff;
}

.cancel-button:hover {
  background-color: #33a3e4;
}

/* Heading */
h2 {
  text-align: center;
  color: white; /* Make heading text white */
  background-color: #38b6ff; /* Match the secondary color background */
  padding: 15px;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); /* Optional shadow for readability */
}

/* Numbers table */
.numbers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.numbers-table th, 
.numbers-table td {
  padding: 15px;
  text-align: center; /* Center-align table text */
  font-size: 16px;
  border: none;
}

.numbers-table th {
  background-color: #ff3131; /* Red background for the table headers */
  color: white; /* White text */
  font-weight: bold;
}

.numbers-table td {
  color: #333; /* Darker text for better contrast */
}

.numbers-table tr:nth-child(even) {
  background-color: var(--hover-color); /* Light blue hover effect */
}

.numbers-table tr:hover {
  background-color: rgba(56, 182, 255, 0.2); /* Hover effect similar to other designs */
  transition: background-color 0.3s ease;
}

p {
  text-align: center;
  color: #777;
  font-size: 16px;
  margin-top: 20px;
}
