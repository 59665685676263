/* Main Manage Users Section */
.manage-users {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background: none;
  box-sizing: border-box;
  margin-left: 250px;
}

/* Manage Users Container */
.manage-users-container {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);  /* Add softer shadows */
  width: 100%;
  max-width: 1100px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

/* Manage Users Heading */
h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  background-color: #38b6ff;  /* Secondary color */
  padding: 20px;
  border-radius: 12px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);  /* Optional shadow for readability */
  margin-bottom: 30px;
}

/* Create User Button */
button.create-user {
  font-size: 16px;
  background-color: #38b6ff;  /* Secondary color */
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button.create-user:hover {
  background-color: #ff3131;
  transform: scale(1.05);
}

/* User Table */
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.user-table th, .user-table td {
  padding: 15px;
  text-align: center;
  font-size: 16px;
  color: #333;
}

.user-table th {
  background-color: #ff3131;
  color: #fff;
  font-weight: 700;
}

.user-table td {
  background-color: rgba(56, 182, 255, 0.1); /* Light blue for row backgrounds */
  border-bottom: 2px solid #eee;
}

.user-table tr:hover td {
  background-color: rgba(56, 182, 255, 0.2);
  transition: background-color 0.3s ease-in-out;
}

/* Buttons for Edit and Delete */
button {
  padding: 10px 15px;
  background-color: #38b6ff;  /* Secondary color */
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #ff3131;  /* Red on hover */
  transform: scale(1.05);  /* Slight zoom effect on hover */
}

button:last-child {
  background-color: #e74c3c;  /* Red for delete button */
}

button:last-child:hover {
  background-color: #c0392b;  /* Darker red on hover for delete */
}

/* Modal Styling for Create/Edit User */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 500px;  /* Modal width */
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
  animation: zoomIn 0.3s ease-out;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-content h2 {
  color: #fff;
  background-color: #38b6ff;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modal-content form input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.modal-content button {
  padding: 12px;
  background-color: #38b6ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.modal-content button:last-child {
  background-color: #e74c3c;  /* Red for cancel/delete */
}

.modal-content button:hover {
  background-color: #ff3131;
}

/* Delete Confirmation Popup */
.delete-confirmation {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 320px;
}

.delete-confirmation button {
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
}
