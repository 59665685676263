:root {
  --sidebar-width: 250px; /* Adjust as per your sidebar width */
  --primary-color: #3B6282;
  --hover-color: rgba(56, 182, 255, 0.1);
  --background-color: #fff;
  --modal-background-color: rgba(255, 255, 255, 0.9);
  --modal-overlay-color: rgba(0, 0, 0, 0.5);
  --button-background-color: #3B6282;
  --button-hover-color: #5b7596;
  --button-cancel-color: #e74c3c;
}

/* Full Width content with margin-left to avoid overlap */
.full-width {
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  box-sizing: border-box;
  padding: 20px;
}

/* Dropdown filters and selections */
.dropdown-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.dropdown-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

.date-selection {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

/* Button styles */
.buttons-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.fetch-logs-button,
.clear-button {
  padding: 10px 20px;
  background-color: var(--button-background-color);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.fetch-logs-button:hover,
.clear-button:hover {
  background-color: var(--button-hover-color);
}

/* Message logs section */
.message-logs {
  margin-top: 50px;
  width: 100%;
  overflow-x: auto;
}

.message-log-table {
  width: 100%;
  border-collapse: collapse;
}

.message-log-table th,
.message-log-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}

.message-log-table th {
  background-color: var(--primary-color);
  color: white;
}

.message-log-table td {
  background-color: rgb(255, 255, 255); /* Transparent white */
  color: #333;
}

.message-log-table tr:hover td {
  background-color: rgba(241, 241, 241, 0.8); /* Lighter transparent hover */
}

/* Message content */
.message-content {
  display: block;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 300px;
}

/* Stats container */
.message-stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stats-box {
  flex: 1;
  min-width: 150px;
  padding: 20px;
  background-color: rgb(255, 255, 255); /* Transparent white background */
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.stats-box h4 {
  margin-bottom: 10px;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
}

.stats-box p {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--modal-overlay-color);
  z-index: 999;
}

/* Modal container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1000;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  background: var(--modal-background-color);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

.modal textarea {
  width: 100%;
  height: 60px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-actions button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions .send-button {
  background-color: var(--button-background-color);
  color: white;
  transition: background-color 0.3s ease;
}

.modal-actions .cancel-button {
  background-color: var(--button-cancel-color);
  color: white;
  transition: background-color 0.3s ease;
}

.modal-actions .send-button:hover {
  background-color: var(--button-hover-color);
}

.modal-actions .cancel-button:hover {
  background-color: #c0392b;
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Stack elements vertically on smaller screens */
  .dropdown-filters {
    flex-direction: column;
  }

  .message-stats {
    flex-direction: column;
    gap: 15px;
  }

  .stats-box {
    width: 100%;
  }

  .buttons-container {
    flex-direction: column;
    gap: 15px;
  }

  /* Ensure modal doesn't overflow */
  .modal {
    width: 95%;
    padding: 15px;
  }

  /* Adjust full-width class to remove margin when sidebar is collapsed */
  .full-width {
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .full-width {
    margin-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
  }
}
