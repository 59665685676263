/* Main container for the home page */
.home-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Inner container for content */
.home-container {
  text-align: center;
  background-color: #062664;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  

}

/* Styling for the company logo */
.company-logo {
  width: 250px;  /* Adjust this size according to the image dimensions */
}

/* Container for the login buttons */
.login-box {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Styling for login buttons */
.login-button {
  padding: 15px 30px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 200px;  /* Ensures the buttons are wider */
}


