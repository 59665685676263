:root {
  --profile-padding: 25px;
  --profile-bg-color: rgba(255, 255, 255, 0.9);
  --profile-border-radius: 12px;
  --profile-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  --profile-max-width: 800px;
  --profile-heading-bg-color: #38b6ff;
  --profile-heading-color: white;
  --profile-heading-padding: 15px;
  --profile-heading-border-radius: 8px;
  --profile-heading-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  --form-grid-gap: 20px;
  --form-label-color: #3B6282;
  --form-input-bg-color: rgba(255, 255, 255, 0.9);
  --form-input-padding: 12px;
  --form-input-border-radius: 8px;
  --form-input-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --button-bg-gradient: linear-gradient(135deg, #ff3131, #ff6b6b);
  --button-bg-hover-gradient: linear-gradient(135deg, #e52b2b, #ff6b6b);
  --button-padding: 12px 20px;
  --button-border-radius: 8px;
  --button-font-size: 16px;
  --success-message-color: #27ae60;
  --error-message-color: #e74c3c;
}

/* Main container for the user profile */
.user-profile {
  padding: var(--profile-padding);
  background-color: var(--profile-bg-color);  /* Transparent white background */
  border-radius: var(--profile-border-radius);
  box-shadow: var(--profile-box-shadow);  /* Add box-shadow for depth */
  max-width: var(--profile-max-width);
  margin: 0 auto;  /* Center the profile form */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Headings */
h2, h3 {
  color: var(--profile-heading-color);  /* Make heading text white */
  background-color: var(--profile-heading-bg-color);  /* Blue background for headings */
  padding: var(--profile-heading-padding);
  border-radius: var(--profile-heading-border-radius);
  text-align: center;
  text-shadow: var(--profile-heading-shadow);  /* Optional shadow to enhance readability */
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: var(--form-grid-gap);
  margin-bottom: var(--form-grid-gap);
}

.form-grid-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns for city, state, and zip code */
  gap: var(--form-grid-gap);
  margin-bottom: var(--form-grid-gap);
}

.form-group.full-width {
  grid-column: span 2; /* Full-width for street and password */
}

/* Form Group */
.form-group {
  margin-bottom: var(--form-grid-gap);
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--form-label-color);
}

.form-group input {
  width: 70%;
  padding: var(--form-input-padding);
  font-size: var(--button-font-size);
  border-radius: var(--form-input-border-radius);
  border: none;
  box-shadow: var(--form-input-box-shadow);  /* Light shadow for inputs */
  background-color: var(--form-input-bg-color);  /* Transparent background for inputs */
}

/* Update Button */
.update-button {
  background: var(--button-bg-gradient);  /* Gradient red background */
  color: white;
  padding: var(--button-padding);
  border: none;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: var(--button-font-size);
  grid-column: span 2; /* Make the button span across both columns */
}

.update-button:hover {
  background: var(--button-bg-hover-gradient);  /* Darker red on hover */
}

/* Success and Error Messages */
.success-message {
  color: var(--success-message-color);  /* Green for success message */
  margin-bottom: 15px;
  font-weight: bold;
}

.error-message {
  color: var(--error-message-color);  /* Red for error message */
  margin-bottom: 15px;
  font-weight: bold;
}
