.admin-profile {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2, h3 {
    color: #3B6282;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    border-color: #3B6282;
    outline: none;
  }
  
  .update-button {
    background-color: #3B6282;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .update-button:hover {
    background-color: #2f4a6d;
  }
  
  .success-message, .error-message {
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  
  h3 {
    margin-top: 40px;
  }
  
  @media (max-width: 768px) {
    .admin-profile {
      padding: 20px;
      margin: 20px;
    }
  
    h2, h3 {
      font-size: 24px;
    }
  
    input {
      font-size: 14px;
    }
  
    .update-button {
      font-size: 14px;
      padding: 10px;
    }
  }
  