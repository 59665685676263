:root {
  --admin-primary-color: #718093;
  --admin-secondary-color: #5a6a7d;
  --admin-background-color: #062664;
  --admin-error-color: red;
  --admin-input-border-color: #ddd;
  --admin-input-border-focus-color: #5a6a7d;
  --admin-input-padding: 12px;
  --admin-input-font-size: 16px;
  --admin-input-border-radius: 5px;
  --admin-input-background-color: #f9f9f9;
  --admin-button-padding: 12px;
  --admin-button-font-size: 16px;
  --admin-border-radius: 5px;
  --admin-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  --admin-login-width: 350px;
  --admin-logo-width: 200px;
  --admin-heading-margin: 20px;
}

/* Main container for the admin login page */
.admin-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container for the login form and logo */
.admin-login-container {
  background-color: var(--admin-background-color);
  padding: 40px;
  border-radius: var(--admin-border-radius);
  box-shadow: var(--admin-box-shadow);
  text-align: center;
  width: var(--admin-login-width);
}

/* Logo at the top of the form */
.admin-login-logo {
  width: var(--admin-logo-width);
  margin-bottom: var(--admin-heading-margin);
}

/* Header for the login form */
h2 {
  color: var(--admin-primary-color);
  margin-bottom: var(--admin-heading-margin);
}

/* Style for error messages */
.admin-error-message {
  color: var(--admin-error-color);
  margin-bottom: 10px;
}

/* Form group for label and input fields */
.admin-form-group {
  margin-bottom: 20px;
  text-align: left;
}

.admin-form-group label {
  display: block;
  color: var(--admin-primary-color);
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input field styles */
.admin-login-input {
  width: 93%;
  padding: var(--admin-input-padding);
  font-size: var(--admin-input-font-size);
  background-color: var(--admin-input-background-color);
  border-radius: var(--admin-input-border-radius);
  border: 1px solid var(--admin-input-border-color);
  transition: border-color 0.3s ease;
}

/* Input focus state */
.admin-login-input:focus {
  border-color: var(--admin-input-border-focus-color);
  outline: none;
}

/* Button styles */
.admin-login-button {
  width: 100%;
  padding: var(--admin-button-padding);
  background-color: var(--admin-primary-color);
  color: white;
  border: none;
  border-radius: var(--admin-border-radius);
  cursor: pointer;
  font-size: var(--admin-button-font-size);
  transition: background-color 0.3s ease;
}

.admin-login-button:hover {
  background-color: var(--admin-secondary-color);
}
