/* Sidebar container */
.sidebar {
  width: 250px;
  background-color: #ffffff;  /* White background */
  position: relative;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-right: 1px solid #ddd;
}

/* Sidebar logo */
.sidebar-logo {
  margin-bottom: 40px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.logo {
  width: 150px;  /* Adjust logo size */
}

/* Sidebar menu styles */
.sidebar-menu {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: left;
  z-index: 1;
  margin-top: 20px;
}

/* Sidebar menu item */
.sidebar-item {
  padding: 15px 20px;
  color: #899BBD;  /* Set text color to #899BBD */
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Links inside the sidebar */
.sidebar-link {
  color: #899BBD;  /* Set link color to #899BBD */
  text-decoration: none;
  display: block;
}

.sidebar-link:hover {
  color: #144cb9;  /* Change text color to blue on hover */
}

/* Hover effect for menu items */
.sidebar-item:hover {
  background-color: #f5f5f5;  /* Light gray background on hover */
  color: #144cb9;
}

/* Logout button */
.sidebar-item.logout {
  color: #899BBD;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-item.logout:hover {
  background-color: #f5f5f5;  /* Light gray background on hover */
  color: #144cb9;
}

/* Icon styles */
.menu-icon {
  margin-right: 10px;  /* Space between icon and text */
  color: #899BBD;  /* Set icon color to #899BBD */
}
