/* Main container for the user login page */
.user-login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container for the user login form and logo */
.user-login-container {
  background-color: #062664;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
}

/* Logo at the top of the form */
.user-login-logo {
  width: 200px;
  margin-bottom: 20px;
}

/* Header for the user login form */
h2 {
  color: #718093;
  margin-bottom: 20px;
}

/* Style for error messages */
.user-login-error-message {
  color: red;
  margin-bottom: 10px;
}

/* Form group for label and input fields */
.user-login-form-group {
  margin-bottom: 20px;
  text-align: left;
}

.user-login-form-group label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input field styles */
.user-login-input {
  width: 93%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

/* Button styles */
.user-login-button {
  width: 100%;
  padding: 12px;
  background-color: #718093;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.user-login-button:hover {
  background-color: #5a6a7d;
}
