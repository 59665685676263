/* User Sidebar container */
.user-sidebar {
  width: 250px;
  background-color: #ffffff;  /* White background */
  position: relative;
  padding: 20px;
  height: 100vh;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

/* Sidebar logo */
.sidebar-logo {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.logo {
  width: 150px;  /* Adjust logo size */
}

/* Sidebar menu styles */
.user-sidebar-menu {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: left;
  z-index: 1;
  margin-top: 20px;
}

/* Sidebar menu item */
.user-sidebar-item {
  padding: 15px 20px;
  color: #899BBD;  /* Updated text color */
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Links inside the sidebar */
.user-sidebar-link {
  color: #899BBD;  /* Updated link color */
  text-decoration: none;
  display: block;
}

.user-sidebar-link:hover {
  color: #144cb9;  /* Change text color to blue on hover */
}

/* Hover effect for menu items */
.user-sidebar-item:hover {
  background-color: #f5f5f5;  /* Light gray background on hover */
  color: #144cb9;
}

/* Logout button */
.user-sidebar-item.logout {
  color: #899BBD;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.user-sidebar-item.logout:hover {
  background-color: #f5f5f5;  /* Light gray background on hover */
  color: #144cb9;
}

/* Icon styles */
.menu-icon {
  margin-right: 10px;  /* Space between icon and text */
  color: #899BBD;  /* Icon color same as text */
}

/* Media Queries for Responsive Design */

/* For tablets and smaller laptops */
@media (max-width: 1024px) {
  .user-sidebar {
    width: 200px;
  }

  .user-sidebar-item {
    font-size: 16px;
    padding: 12px 18px;
  }

  .logo {
    width: 120px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .user-sidebar {
    width: 70px;
    padding: 10px;
  }

  .user-sidebar-item {
    font-size: 14px;
    padding: 8px;
    text-align: center;
  }

  .user-sidebar-link {
    display: none;  /* Hide text for mobile */
  }

  .user-sidebar:hover .user-sidebar-link {
    display: block;  /* Show text on hover */
  }

  .logo {
    width: 50px;
  }

  .sidebar-logo {
    padding: 10px 0;
  }

  .user-sidebar:hover {
    width: 250px;  /* Expand sidebar on hover for mobile */
  }
}

/* For small mobile devices */
@media (max-width: 480px) {
  .user-sidebar {
    width: 50px;
  }

  .logo {
    width: 40px;
  }

  .user-sidebar-item {
    font-size: 12px;
    padding: 6px;
  }

  .user-sidebar:hover {
    width: 200px;  /* Expand sidebar on hover for small screens */
  }
}
