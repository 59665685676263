/* Modal styling for Create/Edit User */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  /* Transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  /* Ensure modal is on top */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    color: #3B6282;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .modal-content button {
    padding: 10px 15px;
    background-color: #718093;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .modal-content button:hover {
    background-color: #3B6282;
  }
  
  .modal-content .close-button {
    background-color: #e74c3c;
  }
  
  .modal-content .close-button:hover {
    background-color: #c0392b;
  }
  